import React, {useEffect} from "react";
import ContactForm from "../Components/ContactForm";
import InfoSection from "../Components/InfoSection";

const Donations = () => {
    
    
    useEffect(() => {
        
    }, [] )

    return (
        <React.Fragment>
            <div className="bg-cartmell-white">

                <section className="flex flex-row relative" style={{justifyContent: "end"}}>
                    <div className="picture-back">
                       
                    </div>
                    <div className="donation-box-container relative bg-cartmell-beige-darker">
                        <div className="donation-box ">
                            <div className="donation-box title banner-text-control ">
                                <h1 className="font-charm font-bold text-cartmell-red">Ways of Giving</h1>
                            </div>
                            <div className="donation-box tile">

                                <p className="text-xl">As a not for profit, charitable 501(c)(3) organization, Cartmell
                                    Communities gratefully accepts tax deductible donations to assist with its mission
                                    of caring
                                    for the elderly.</p>
                            </div>
                            <div className="flex flex-col md:flex-row rows">
                                <div className="donation-box tile text-center">
                                    <h2 className="text-3xl font-charm font-bold text-cartmell-red">Cash Gifts</h2>
                                    <br/>
                                    <p className="text-xl">Checks should be made payable to:</p>
                                    <div className="w-100 p-5">

                                        <p className="text-xl">Cartmell Foundation<br/>
                                            100 Cartmell Drive,<br/>
                                            Palestine, TX 75801</p>
                                    </div>
                                </div>
                                <div className="donation-box tile text-center">
                                    <h2 className="text-3xl font-charm font-bold text-cartmell-red">Estate Gifts</h2>
                                    <div className="w-100 p-5">

                                        <p className="text-xl ps-2">There are a variety of ways to make gifts as a part
                                            of
                                            your estate plan, from outright bequests to charitable trusts that can also
                                            benefit
                                            your family. Please contact our office for information on how to make out a
                                            testamentary bequest or trust gift to the foundation and other charitable
                                            gifting
                                            alternatives.</p>
                                    </div>
                                </div>
                            </div>
                                <div className="flex flex-col md:flex-row rows" >

                                    <div className="donation-box tile text-center">
                                        <h2 className="text-3xl font-charm font-bold text-cartmell-red">Stock Gifts</h2>
                                        <div className="w-100 p-5">

                                            <p className="text-xl">Donors can avoid capital gains tax on stocks donated
                                                to a
                                                charitable organization. Please contact our office for ways of donating
                                                stocks,
                                                bonds, or mutual funds.</p>
                                        </div>
                                    </div>
                                    <div className="donation-box tile text-center">
                                        <h2 className="text-3xl font-charm font-bold text-cartmell-red">Designated
                                            Gifts</h2>
                                        <div className="w-100 p-5">

                                            <p className="text-xl">Donors may designate a gift to be used for a specific
                                                purpose
                                                to
                                                meet the needs of our residents and facility.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </section>
                <InfoSection location="cartmell"/>
            </div>
        </React.Fragment>
    );
}

export default Donations;