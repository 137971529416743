import React from "react";
import ContactForm from "../Components/ContactForm";

const MissCartmell = () => {

    return (
        <React.Fragment>
            <section
                className="flex flex-col lg:flex-row bg-cartmell-beige p-5 items-center justify-center lg:space-x-10 ">
                <div className="flex flex-col justify-center lg:border-r-4 border-cartmell-white">
                    <div className="lg:pr-10 py-10 space-y-3">
                        <img className="drop-shadow-md" src="../../dist/img/Miss_Cartmell-01.jpg"
                             alt="Sarah E.J. Cartmell"/>
                        <h1 className="font-bold text-cartmell-red text-4xl">Sarah E.J. Cartmell</h1>
                        <p className="font-bold text-cartmell-red text-lg">August 8, 1869 - September 6, 1948</p>
                    </div>
                </div>
                <div className="flex flex-col justify-center space-y-3 text-center">
                    <h2 className="font-bold font-charm text-cartmell-red text-4xl">"Charity is active
                        goodness,<br/> motivated by Christian love."</h2>
                    <p className="font-bold font-charm text-cartmell-red text-lg">-Sarah Cartmell</p>
                </div>
            </section>
            <section className="bg-cartmell-white w-full flex justify-center py-5">
                <div>
                    <ContactForm/>

                </div>
            </section>
        </React.Fragment>
    );
}

export default MissCartmell;