import React, {useEffect} from "react";
import Glide from "@glidejs/glide";
import "@glidejs/glide/dist/css/glide.core.min.css";
import "@glidejs/glide/dist/css/glide.theme.min.css";
const Glider = ({data}) => {
    
   

    const config = {
        type: 'carousel',
        perView: 1,
        autoplay: 8000,
        animationTimingFunc: 'ease-in-out',
        animationDuration: 2000,
        hoverpause: true,
        
    }
    const [slider] = React.useState(new Glide('.glide',config));
  
    useEffect(() => {
        slider.mount();
    },[slider])
  
    
    return (
        <React.Fragment>
            <section className=" w-full  mx-auto  flex justify-center items-center" >
              
                <div className="glide bg-cartmell-beige" style={{maxWidth:"1200px"}}>
                    <div className="glide__track" data-glide-el="track">
                        <ul className="glide__slides">
                            {data.map((item, index) => {
                                    if(item === undefined) return "";
                                    return (
                                        <li key={index} className="glide__slide cursor-default">
                                            <img className="mx-auto" src={'/dist/img/' + item.image} alt={item.alt}/>
                                        </li>
                                    )
                                }
                            )}
                        </ul>
                    </div>
                    <div className="glide__arrows" data-glide-el="controls">
                        <button className="glide__arrow glide__arrow--left" data-glide-dir="<">prev</button>
                        <button className="glide__arrow glide__arrow--right" data-glide-dir=">">next</button>
                    </div>
                    <div className="glide__bullets" data-glide-el="controls[nav]">
                        {data.map((item, index) => {
                            return (
                                <button key={index} className="glide__bullet" data-glide-dir={`=${index - 1}`}></button>
                            )
                        })}
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default Glider;