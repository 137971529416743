import React, {useEffect, useState} from "react";
import $ from 'jquery';
import {Link, useNavigate, useLocation} from "react-router-dom";


const Menu = () => {
    
    const [timer, setTimer] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    
    
    useEffect(()=>{
        if(timer === null || timer === 0) return;
        
        const timerId = setTimeout(()=>{
            
        })
    },[timer])
    
    useEffect(() => {
        
        const menuItems = $('nav > ul > li');
        const location =  window.location.pathname;
        // console.log(menuItems);
       
        menuItems.each((index,menuItem) => {
            const hasSubMenu = $(menuItem).children('div');
            // console.log(menuItem)
            if (hasSubMenu.length > 0) {
                let hasLink = false;
                
                hasSubMenu.children('a').each((_, subItem) => {
                    const result = checkLinkForActive(subItem);
                   if(result){
                       hasLink = true;
                   }

                

            });
                if (hasLink) {
                    $(menuItem).addClass('menu-active');
                } else {
                    $(menuItem).removeClass('menu-active');
                }


                $(menuItem).on("mouseover",() => {
                    
                    $(menuItem).children('div').first().attr('style', '');
                });
                $(menuItem).on("mouseout",() => {
                    $(menuItem).children('div').first().attr('style', 'display: none');
                });
                
            }else{
                if(index !== menuItems.length - 1) {
                    
                checkLinkForActive(menuItem.firstChild);
                }
            }
           
        });
            $(menuItems).on('click', (e) => {
              
                
                const selection = $(e.target);
                if(selection.children('a').length <= 0) return;
              // console.log(selection.children('a').attr('href'));
              // selection.children('a').trigger('click');
                navigate(selection.children('a').attr('href'));
               
            })
        
        $('#burger').on('click', () => {
           
        })
    }, [location])

    const checkLinkForActive = (menuItem) => {
        // const location =  window.location.pathname;
        // console.log(location)
        // console.log(menuItem.href.split("/").pop() , " adf " ,location.pathname.toString().split("/").pop());
        if(menuItem.href.split("/").pop() === location.pathname.toString().split("/").pop() || menuItem.href.split("/").pop() === "/" ){
            // console.log("active");
          
                $(menuItem).addClass('menu-active');
                return true;
        }else{
            $(menuItem).removeClass('menu-active');
            return false;
        }
    }
    
    
    const changeVisibility =(e) => {
        
        const menu = $('#menu');
        const selection = e.target;

        if (selection.closest("nav") === null) {
            menu.addClass('hidden');
        }
        if (selection.closest("#burger") !== null || (selection.closest("#menu") && !menu.hasClass('hidden'))) {
            if (menu.hasClass('hidden')) {
                menu.removeClass('hidden');
            } else {
                
                menu.addClass('hidden');
                
                // if the user clicks on the parent li, programmatically click on the anchor
                if(selection.tagName === "LI"){
                    const child = selection.firstChild;
                    
                    if(child.textContent.toLowerCase() === "contact"){
                        
                    }
                   
                }
            }
        }
    }
    
    const handleContactScroll = (e) => {
      
        const contactForm = $('#contact');
        if(contactForm.length <= 0 ) return;
        
        contactForm[0].scrollIntoView({ block: "center", inline: "center", behavior: "smooth", alignToTop: false });
    }
    
    return (
        <React.Fragment>
            <div className="sticky absolute top-0 z-50" onClick={changeVisibility}>
                
                <nav
                    className="bg-cartmell-white text-cartmell-red font-bold lg:text-2xl flex flex-col lg:flex-row justify-center items-end shadow-md z-50">
                    <div className="cursor-pointer" id="burger" >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 m-3 text-cartmell-red lg:hidden"
                             viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd"
                                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                  clipRule="evenodd"/>
                        </svg>
                    </div>
                    <ul className="hidden absolute lg:static bg-cartmell-white top-0 mt-10 lg:mt-0 lg:flex flex-row font-charm  justify-around text-right lg:text-left shadow-md lg:shadow-none whitespace-nowrap z-50"
                        id="menu">
                        <li style={{paddingTop:"10px"}}>
                            <Link className="p-3 hover:bg-cartmell-red hover:text-cartmell-white transition-colors duration-500" to="/">Home</Link>
                        </li>
                        <li className="hidden lg:block relative cursor-pointer p-3 hover:bg-cartmell-red hover:text-cartmell-white transition-colors duration-500"
                        >Our
                            Communities
                            <div
                                className="flex flex-col absolute mt-3 -ml-3 w-full bg-cartmell-white text-lg shadow-md"
                                style={{display: "none"}}>
                                <Link key={0} className="p-3 text-cartmell-red hover:bg-cartmell-red hover:text-cartmell-white "
                                      to="cartmell-lakes">Independent Living</Link>
                                <Link key={1} className="p-3 text-cartmell-red hover:bg-cartmell-red hover:text-cartmell-white"
                                      to="windermere">Assisted Living</Link>
                                <Link key={2} className="p-3 text-cartmell-red hover:bg-cartmell-red hover:text-cartmell-white"
                                   to="/reflections">Memory Care</Link>
                            </div>
                        </li>
                        <li style={{paddingTop:"10px"}} >
                            <Link className="p-3 lg:hidden hover:bg-cartmell-red hover:text-cartmell-white transition-colors duration-500" to="/cartmell-lakes">Independent Living</Link>
                        </li>
                        <li style={{paddingTop:"10px"}} >
                            <Link className="p-3 lg:hidden hover:bg-cartmell-red hover:text-cartmell-white transition-colors duration-500" to="/windermere">Assisted Living</Link>
                        </li>
                        <li style={{paddingTop:"10px"}}>
                            <Link className="p-3 lg:hidden hover:bg-cartmell-red hover:text-cartmell-white transition-colors duration-500" to="/reflections">Memory Care</Link>
                        </li>
                        <li style={{paddingTop:"10px"}} >
                            <Link className="p-3 hover:bg-cartmell-red hover:text-cartmell-white transition-colors duration-500" to="/aboutus" id="myVid">About Us</Link>
                           
                        </li>
                        <li style={{paddingTop:"10px"}} >
                            <Link className="p-3 hover:bg-cartmell-red hover:text-cartmell-white transition-colors duration-500" to="/donations">Donations</Link>
                        </li>
                        <li style={{paddingTop:"10px"}} className="p-3 hover:bg-cartmell-red hover:text-cartmell-white transition-colors duration-500">
                            <Link  onClick={(e)=> handleContactScroll(e)}>Contact</Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </React.Fragment>
    );
}

export default Menu;