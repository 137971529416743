import {createAction, createSlice} from '@reduxjs/toolkit';
import modal from "../../App/Components/Modal";
import $ from "jquery";

export const modalSlice = createSlice( {
   
        name: 'modal',
        initialState: {
            value: {
                image: null,

            },
        },
        reducers: {
            resetModal(state) {
                animateModal();
                state = {
                    image: null
                }
            },
            setImage: (state, action) => {
                state.value = action.payload;
                animateModal();
            }
        }
        
});

let modalTimer;

const animateModal = () => {
    const modal =  $('#modal');
    if (modal.length <= 0) return;
    
    if(modalTimer !== undefined && modalTimer !== null) clearTimeout(modalTimer);
    
    if(modal.hasClass('show')) {
        modal.attr('style', 'opacity: 0');
        modalTimer = setTimeout(() => {
            modal.removeClass('show');
        }, 250);
    }else{
        modal.addClass('show');
        modalTimer = setTimeout(() => {
            modal.attr('style', 'opacity: 1');
        }, 100);
        
    }
}

export const {resetModal,setImage} = modalSlice.actions;
export default modalSlice.reducer;