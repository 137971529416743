import {createAction, createSlice} from '@reduxjs/toolkit';
import $ from "jquery";
import {modalSlice} from "./Modal";

export const shouldShowSlice = createSlice({
    name: 'shouldShow',
    initialState: {
        value: false
    },
    reducers: {
        setShouldShow: (state, action) => {
            state.value = action.payload;
        }
    }
})

export const {setShouldShow} = shouldShowSlice.actions;
export default shouldShowSlice.reducer;
