import React, {useEffect} from "react";
import {EmailJSResponseStatus, } from "@emailjs/browser";
import $ from "jquery";
import * as emailjs from "@emailjs/browser";



const ContactForm = ({scrollRefs}) => {

    

    useEffect(() => {
        emailjs.init('user_ma5Oi2SxwA1i46jFyYTZX');
        
        document.getElementById('contact-form').addEventListener('submit', function (e) {
            e.preventDefault();
            const validation = $('#validate');
            // generate a five digit number for the contact_number variable
            // this.contact_number.value = Math.random() * 100000 | 0;
            // these IDs from the previous steps
            emailjs.sendForm('contact_service', 'contact_form', this)
                .then(function () {
                    
                    validation.val('Sent');
                    validation.removeClass("cartmell-bg-red");
                    validation.addClass("bg-green-500");
                    
                    setTimeout(function () {
                        
                        $('#contact-form').trigger('reset');
                        validation.removeClass("bg-green-500");
                        validation.addClass("cartmell-bg-red");
                        validation.val('Send');
                    }, 4000);
                    
                }, function (error) {
                    
                    validation.val('Try Again');
                    validation.removeClass("cartmell-bg-red");
                    validation.addClass("bg-red-500");
                    
                    setTimeout(function () {
                        
                        $('#contact-form').trigger('reset');
                        validation.val('Send');
                        validation.removeClass("bg-red-500");
                        validation.addClass("cartmell-bg-red");
                    }, 4000)
                });
        });
    },[])
   
    
    return (
        <React.Fragment>
            <div  id="contact" className={"bg-cartmell-white py-5 w-full"}>
                <div className="w-full  text-center items-center sm:p-5" style={{maxWidth:"500px"}}>
                    <h3 className="font-charm font-bold headerText  text-cartmell-red">Ready to embark on your journey to
                        Cartmell Communities?</h3>
                    <h4 className="text-lg pt-5 px-3 ">If you or a loved one are ready to learn more and become a member
                        of our community, then don't hesitate to contact us today!</h4>
                    <form name="contact" className="flex flex-col text-left p-5 drop-shadow-md"
                          id="contact-form">
                        <input type="hidden" name="contact_number"/>
                        <label htmlFor="input1">Name:</label>
                        <input className="focus:border-cartmell-red focus:ring-cartmell-red" type="text"
                               name="user_name" id="input1"/>
                        <label htmlFor="input2">Email:</label>
                        <input className="focus:border-cartmell-red focus:ring-cartmell-red" type="email"
                               name="user_email" id="input2"/>
                        <label htmlFor="input3">Phone Number:</label>
                        <input className="focus:border-cartmell-red focus:ring-cartmell-red" type="tel"
                               name="user_phone" id="input3"/>
                        <label htmlFor="input4">Message:</label>
                        <textarea className="mb-5 focus:border-cartmell-red focus:ring-cartmell-red" name="message"
                                  id="input4"></textarea>
                        <input name="submit" id="validate" type="submit" value="Send"
                               className="bg-cartmell-red p-3 rounded-lg container mx-auto text-cartmell-white text-xl font-charm font-bold shadow-md transform hover:scale-105 hover:drop-shadow-lg transition duration-500 cursor-pointer"/> 
                    </form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ContactForm;