import React from "react";

const SloganSection = () => {
    
    
    return (
        <section className="bg-cartmell-red">
            <div className="flex flex-row  justify-center p-10 drop-shadow-md ">
                <h3 className="banner-text-control text-cartmell-white">Where
                    dignity, love and professionalism are a way of life</h3>
            </div>
        </section>
    )
}

export default SloganSection;