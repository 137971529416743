import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

const Map = ({location, width}) => {
    const [info, setInfo] = useState({
        title: "Windermere is located at:",
        street: "30 Variah Street",
        cityStateZip: "Palestine, TX 75801",
        mapsLink: "https://goo.gl/maps/PNSrntomrmwiDiaRA",
        mainMapLink: "https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d432942.41518614255!2d-95.7399923147174!3d32.03237489989484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d32.3289088!2d-95.29589759999999!4m5!1s0x8648638850b9facb%3A0x17c40b4ccf361d9d!2s30%20S%20Variah%20St%2C%20Palestine%2C%20TX%2075801!3m2!1d31.7433196!2d-95.6474655!5e0!3m2!1sen!2sus!4v1637792739078!5m2!1sen!2sus",
        phone:"(903)727-8600"
    });
    useEffect(() => {
        console.log(location)
        if(location !== null && location === "cartmell"){
            setInfo({
                title: "Our administrative office is located at:",
                street: "100 Cartmell Drive",
                cityStateZip: "Palestine, TX 75801",
                mapsLink: "https://maps.app.goo.gl/KLFd911Yoa2XrU6T9",
                mainMapLink: "https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d432925.1915127499!2d-95.79367352978733!3d32.036017915890206!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d32.3289088!2d-95.29589759999999!4m5!1s0x86486478221e119b%3A0x10bc30a31c515d2b!2s100%20Cartmell%20Drive%2C%20Palestine%2C%20TX!3m2!1d31.7425587!2d-95.6444159!5e0!3m2!1sen!2sus!4v1731358962596!5m2!1sen!2sus",
                phone:"(903)727-8100"
            })
        }else{
            setInfo({
                title: "Windermere is located at:",
                street: "30 Variah Street",
                cityStateZip: "Palestine, TX 75801",
                mapsLink: "https://goo.gl/maps/PNSrntomrmwiDiaRA",
                mainMapLink: "https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d432942.41518614255!2d-95.7399923147174!3d32.03237489989484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d32.3289088!2d-95.29589759999999!4m5!1s0x8648638850b9facb%3A0x17c40b4ccf361d9d!2s30%20S%20Variah%20St%2C%20Palestine%2C%20TX%2075801!3m2!1d31.7433196!2d-95.6474655!5e0!3m2!1sen!2sus!4v1637792739078!5m2!1sen!2sus",
                phone:"(903)727-8600"
            })
        }
    },[])
    return (
        <React.Fragment>
            <div className={"flex flex-row justify-center bg-cartmell-white py-5 w-full " }>
                <div className={"flex flex-col    md:px-0" +  (width !== undefined ? width : " w-full"  )}>
                    <div
                        className="flex flex-col items-center  font-bold text-cartmell-red " >
                        <div className="" style={{flexGrow:0}}>
                            <h2 className="font-charm text-center headerText">{info.title}</h2>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            gap: "1rem",
                            padding: "1rem"
                            
                           
                        }}>

                            <div className=" text-center" >

                                <p style={{fontWeight: 500, fontSize: "1.1rem"}}>Address:</p>
                                <div style={{padding: ".75em"}}>

                                    <Link style={{fontWeight: 700, fontSize: "1.2rem"}} to={info.mapsLink} target="blank">
                                        <p>{info.street}</p>
                                        <p>{info.cityStateZip}</p>
                                    </Link>
                                </div>
                            </div>
                            <div className=" text-center" >
                                <p style={{fontWeight: 500, fontSize: "1.1rem"}}>Phone:</p>
                                <div style={{padding: ".75em"}}>

                                    <Link  style={{fontWeight: 700, fontSize: "1.2rem"}}
                                          to={"tel:" + info.phone.replace(/[()-]/g, "")}>{info.phone}</Link>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="flex flex-col   items-center mb-5 p-4" style={{height:"100%"}}>
                        <iframe
                            className="rounded-lg ring-cartmell-red  px-5 h-full mt-5 lg:mt-0 drop-shadow-md mapHeight" 
                            src={info.mainMapLink}
                            allowFullScreen="" loading="lazy"></iframe>
                    </div>

                </div>
              
            </div>
        </React.Fragment>
    )
}

export default Map;