import React, {useEffect} from "react";
import $ from "jquery";
import {useSelector, useDispatch} from "react-redux";
import {resetModal} from "../../Redux/slice/Modal";

const Modal = () => {
    const modalContents = useSelector((state) => state.modalContents.value);
    const dispatch = useDispatch();
    
    // useEffect(() => {
    //    console.log(modalContents)
    // }, [modalContents]);
    
    
    return (
        <>
            <div id="modal" >
                <div className="background"></div>
                <div className="close" onClick={() => dispatch(resetModal())}></div>
                <div id="modal-content">
                    <img src={modalContents} alt="image of floorplans" style={{maxHeight:"85vh", width:"auto"}}/>
                </div>
            </div>
        </>
    )
}
export default Modal;