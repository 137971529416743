import React from "react";
import ContactForm from "./ContactForm";
import Map from "./Map";

const InfoSection = ({location}) => {
    
    
    return (
        <section className="contact-map__container flex bg-cartmell-white py-5">
            <div className="contact-map">
                <ContactForm/>
                <Map location={location}/>

            </div>
        </section>
    )
}

export default InfoSection;