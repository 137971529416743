import React from "react";

const Footer = () => {
    
    return(
        <React.Fragment>
            <footer className="bg-cartmell-red text-cartmell-white shadow-md text-center pt-5">
                <div className="flex flex-col  justify-center  py-3 space-y-3">
                    <div className="flex flex-row justify-center space-x-5">
                        <img src="../../dist/img/hud.png" alt="Equal Housing Opportunity"
                             title="Equal Housing Opportunity"/>
                        <img src="../../dist/img/latx.png" alt="Leading Age of Texas" title="Leading Age of Texas"/>
                    </div>

                    <div className="footer-text justify-center  space-x-3">
                        <div className="flex flex-row items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"
                                 fill="currentColor">
                                <path
                                    d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"/>
                            </svg>
                            <p><a href="tel:903-727-8100">Telephone: 903-727-8100</a></p>
                        </div>
                        <div className="flex flex-row items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"
                                 fill="currentColor">
                                <path fillRule="evenodd"
                                      d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                                      clipRule="evenodd"/>
                            </svg>
                            <p>Fax: 903-625-9199</p>
                        </div>
                        <div className="flex flex-row items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"
                                 fill="currentColor">
                                <path fillRule="evenodd"
                                      d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                                      clipRule="evenodd"/>
                            </svg>
                            <p><a href="https://goo.gl/maps/PNSrntomrmwiDiaRA" target="blank">100 Cartmell Drive,
                                Palestine, TX 75801</a></p>


                        </div>
                    </div>
                        <div className="flex flex-col justify-center items-center pt-3">
                            <p>©™ {new Date().getFullYear()} Cartmell Communities | All Rights Reserved | <a
                                href="https://webdesignintyler.com/"
                                target="blank">Responsive Design by
                                ETV Software.</a></p>
                        </div>
                </div>
            </footer>
            <script
                src="https://code.jquery.com/jquery-3.6.0.min.js"
                integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4="
                crossOrigin="anonymous"></script>
            <script src="https://unpkg.com/alpinejs@3.7.1/dist/cdn.min.js"></script>

            <script type="text/javascript"
                    src="https://cdn.jsdelivr.net/npm/@emailjs/browser@3/dist/email.min.js"></script>
        
        </React.Fragment>
    );
};

export default Footer;