import {createSlice} from "@reduxjs/toolkit";

export const shouldShowHeightSlice = createSlice({
    name: 'shouldShow',
    initialState: {
        height: 0
    },
    reducers: {
        setShouldShowHeight: (state, action) => {
            state.height = action.payload;
        }
    }
})

export const {setShouldShowHeight} = shouldShowHeightSlice.actions;
export default shouldShowHeightSlice.reducer;