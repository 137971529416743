import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setShouldShow} from "../../../Redux/slice/ShouldShow";
import {setShouldShowHeight} from "../../../Redux/slice/ShouldShowHeight";

const ShouldShow = () => {


    const modalContents = useSelector((state) => state.modalContents.value);
    const dispatch = useDispatch();


    let resizeWindow = () => {
        dispatch(setShouldShow( window.innerWidth < 768));
        dispatch(setShouldShowHeight( window.innerWidth < 1150 ? window.innerWidth * .62 : window.innerWidth > 768 ? 650 : "unset"  ));
        
        
    };

    useEffect(() => {
        resizeWindow();

        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    });
}

export default ShouldShow;