import React from "react";


const Header = () =>{
    
    return (
        <header className="" >
            <div className="bg-cartmell-red flex flex-row justify-center z-10 drop-shadow-md" style={{padding: "1em"}}>
                <img src="../../dist/img/head_logo.png"
                     alt="Cartmell Communities: Quality Living and Compassionate Service" className="mx-auto"/>
            </div>
        </header>
    );
}

export default Header;