import { configureStore } from '@reduxjs/toolkit'
import modalReducer from './slice/Modal'
import shouldShowReducer from './slice/ShouldShow'
import shouldShowHeightReducer from "./slice/ShouldShowHeight";

export default configureStore({
    reducer: {
        modalContents: modalReducer,
        shouldShowContents: shouldShowReducer,
        shouldShowHeightContents: shouldShowHeightReducer
    },
})