import logo from './logo.svg';
import React     from "react";
import './App.css';
import Footer from "./App/Footer/Footer";
import {Route, Routes} from "react-router-dom";
import Home from "./App/Pages/Home";
import CartmellLakes from "./App/Pages/CartmellLakes";
import Donations from "./App/Pages/Dontations";
import Reflections from "./App/Pages/Reflections";
import Windermere from "./App/Pages/Windermere";
import Header from "./App/Header/Header";
import Menu from "./App/Components/Menu";
import AboutUs from "./App/Pages/AboutUS";
import $ from 'jquery';
import MissCartmell from "./App/Pages/MissCartmell";
import Modal from "./App/Components/Modal";
import ShouldShow from "./App/Components/Support/ShouldShow";

// function Header() {
//   return null;
// }

function App() {
    return (
        <div className="bg-cartmell-white relative" style={{display: "flex", flexDirection: "column",  position: "relative"}}>
            <Modal />
            <ShouldShow/>
                <Header/>
                <Menu/>
            
            <div style={{flexGrow: 1}}>
                <Routes>
                    <Route exact path="/" element={<Home/>}></Route>
                    <Route path="/home" element={<Home/>}></Route>
                    <Route path="/cartmell-lakes" element={<CartmellLakes/>}></Route>
                    <Route path="/donations" element={<Donations/>}></Route>
                    <Route path="/aboutus" element={<AboutUs/>}></Route>
                    <Route path="/reflections" element={<Reflections/>}></Route>
                    <Route path="/windermere" element={<Windermere/>}></Route>
                    <Route path="/miss-cartmell" element={<MissCartmell/>}></Route>
                </Routes>
            
            </div>
            <div style={{flexGrow: 0}}>
                <Footer/>
            </div>
        </div>
    );
}

export default App;
